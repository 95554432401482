import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardCard = _resolveComponent("DashboardCard")!
  const _component_DivRow = _resolveComponent("DivRow")!

  return (_openBlock(), _createBlock(_component_DivRow, { class: "d-flex flex-wrap g-5 justify-content-around" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page, index) => {
        return (_openBlock(), _createBlock(_component_DashboardCard, {
          key: index,
          page: page
        }, null, 8, ["page"]))
      }), 128))
    ]),
    _: 1
  }))
}