
import { defineComponent } from 'vue';

import DocMenuConfig from '../../../core/config/MainMenuConfig';
import DashboardCard from './dashboard-card/DashboardCard.vue';
import DivRow from '../../../layout/_shared/DivRow.vue';

export default defineComponent({
  name: 'dashboard-cards',

  components: { DashboardCard, DivRow },

  computed: {
    pages() {
      const { pages } = DocMenuConfig[1];

      return pages;
    }
  }
})
