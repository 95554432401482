import { ButtonsType } from '../enums/buttons.enums';
import ReportType from '../enums/ReportTypes.enums';

const DocMenuConfig = [
  {
    pages: [
      {
        heading: 'Trang chủ',
        route: '/trang-chu',
        svgIcon: 'media/icons/duotune/art/art002.svg',
        fontIcon: 'bi-app-indicator',
      },
    ],
  },
  {
    heading: 'Quản lý',
    route: '/crafted',
    pages: [
      {
        sectionTitle: 'Tài khoản',
        svgIcon: 'media/icons/duotune/communication/com006.svg',
        description: 'Thông tin chi tiết và chỉnh sửa thông tin tài khoản',
        fontIcon: 'bi-person',
        sub: [
          {
            heading: 'Thông tin',
            route: '/quan-ly/tai-khoan/thong-tin',
          },
          {
            heading: 'Cập nhật',
            route: '/quan-ly/tai-khoan/cap-nhat',
          },
        ],
        hiddenLink: '/quan-ly/tai-khoan/thong-tin',
      },
      {
        sectionTitle: 'Quản lý kho',
        route: '/quan-ly/kho/danh-sach',
        description: 'Danh sách, chỉnh sửa và tạo mới kho',
        svgIcon: 'media/icons/duotune/general/gen001.svg',
        fontIcon: 'bi-person',
        sub: [],
        hiddenLink: '/quan-ly/kho/danh-sach',
      },
      {
        sectionTitle: 'Vật tư',
        svgIcon: 'media/icons/duotune/general/gen017.svg',
        description: 'Danh sách, chỉnh sửa và tạo mới vật tư',
        fontIcon: 'bi-person',
        hiddenLink: '/quan-ly/vat-tu/danh-sach',
        sub: [
          {
            heading: 'Quản lý d/mục vật tư',
            route: '/quan-ly/danh-muc-vat-tu',
          },
          {
            heading: 'Quản lý vật tư',
            route: '/quan-ly/vat-tu/danh-sach',
          },
        ],
      },
      {
        sectionTitle: 'Toa xe',
        svgIcon: 'media/icons/duotune/ecommerce/ecm006.svg',
        description: 'Danh sách, chỉnh sửa và tạo mới toa xe',
        fontIcon: 'bi-person',
        hiddenLink: '/quan-ly/toa-xe/danh-sach',
        sub: [
          {
            heading: 'Quản lý d/mục toa xe',
            route: '/quan-ly/danh-muc-toa-xe/danh-sach'
          },
          {
            heading: 'Quản lý toa xe',
            route: '/quan-ly/toa-xe/danh-sach'
          },
        ]
      },
      {
        sectionTitle: 'Quản lý hợp đồng',
        route: '/quan-ly/hop-dong/danh-sach',
        hiddenLink: '/quan-ly/hop-dong/danh-sach',
        description: 'Danh sách hợp đồng và các thông tin liên quan',
        svgIcon: 'media/icons/duotune/general/gen005.svg',
        fontIcon: 'bi-person',
        sub: [],
      },
      {
        sectionTitle: 'Quản lý kế hoạch sửa chữa',
        route: '/quan-ly/ke-hoach-sua-chua/danh-sach',
        description: 'Danh sách kế hoạch sửa chữa',
        hiddenLink: '/quan-ly/ke-hoach-sua-chua/danh-sach',
        svgIcon: 'media/icons/duotune/general/gen055.svg',
        fontIcon: 'bi-person',
        sub: [],
      },
      {
        sectionTitle: 'Quản lý biên bản giải thể',
        route: '/quan-ly/bien-ban-giai-the/danh-sach',
        description: 'Danh sách biên bản giải thể',
        hiddenLink: '/quan-ly/bien-ban-giai-the/danh-sach',
        svgIcon: '',
        customIconName: ButtonsType.FA_FILE_CIRCLE_XMARK,
        fontIcon: '',
        sub: [],
      },
      {
        sectionTitle: 'Báo cáo',
        description: 'Danh sách biên bản giải thể',
        hiddenLink: '/quan-ly/bao-cao',
        svgIcon: 'media/icons/duotune/general/table-duotone.svg',
        fontIcon: '',
        sub: [
          {
            heading: 'Sổ cân đối V/Tư',
            route: `/quan-ly/bao-cao/${ReportType.SO_CAN_DOI_VAT_TU}`,
          },
          {
            heading: 'Bảng kê C/tiết nhập',
            route: `/quan-ly/bao-cao/${ReportType.BANG_KE_CHI_TIET_NHAP}`,
          },
          {
            heading: 'Bảng kê C/tiết xuất',
            route: `/quan-ly/bao-cao/${ReportType.BANG_KE_CHI_TIET_XUAT}`,
          },
          {
            heading: 'Tổng hợp nhập vật tư',
            route: `/quan-ly/bao-cao/${ReportType.TONG_HOP_NHAP_VAT_TU}`,
          },
          {
            heading: 'Tổng hợp xuất vật tư',
            route: `/quan-ly/bao-cao/${ReportType.TONG_HOP_XUAT_VAT_TU}`,
          },
          {
            heading: 'Chi tiết xuất theo đối tượng',
            route: `/quan-ly/bao-cao/${ReportType.CHI_TIET_XUAT_THEO_DOI_TUONG}`,
          },
          {
            heading: 'Theo dõi thực hiện hợp đồng',
            route: `/quan-ly/bao-cao/${ReportType.THEO_DOI_THUC_HIEN_HOP_DONG}`,
          },
          // {
          //   heading: 'Tổng hợp vật tư theo đối tượng',
          //   route: `/quan-ly/bao-cao/${ReportType.TONG_HOP_VAT_TU_THEO_DOI_TUONG}`,
          // },
        ],
      },
      {
        sectionTitle: 'Quản lý ĐMCĐ',
        route: '/quan-ly/dinh-muc-co-dinh/danh-sach',
        hiddenLink: '/quan-ly/dinh-muc-co-dinh/danh-sach',
        description: 'Quản lý ĐMCĐ',
        svgIcon: 'media/icons/duotune/custom/up-to-line.svg',
        fontIcon: 'bi-person',
        sub: [],
      },
      {
        sectionTitle: 'Quản lý cấp sửa chữa',
        route: '/quan-ly/cap-sua-chua/danh-sach',
        hiddenLink: '/quan-ly/cap-sua-chua/danh-sach',
        description: 'Quản lý Cấp sửa chữa',
        svgIcon: 'media/icons/duotune/custom/wrench.svg',
        fontIcon: 'bi-person',
        sub: [],
      },
      {
        sectionTitle: 'Quản lý bộ phận',
        route: '/quan-ly/bo-phan/danh-sach',
        hiddenLink: '/quan-ly/bo-phan/danh-sach',
        description: 'Danh sách, chỉnh sửa và tạo mới bộ phận',
        svgIcon: 'media/icons/duotune/general/gen018.svg',
        fontIcon: 'bi-person',
        sub: [],
      },
      {
        sectionTitle: 'Quản lý chức vụ',
        route: '/quan-ly/chuc-vu/danh-sach',
        hiddenLink: '/quan-ly/chuc-vu/danh-sach',
        description: 'Danh sách, chỉnh sửa và tạo mới chức vụ',
        svgIcon: 'media/icons/duotune/art/art008.svg',
        fontIcon: 'bi-person',
        sub: [],
      },
    ],
  },
];

export default DocMenuConfig;
