import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card card-stretch shadow mb-5" }
const _hoisted_2 = { class: "card-header d-flex justify-content-center" }
const _hoisted_3 = { class: "card-title text-center bold" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "card-footer text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.page.hiddenLink,
    class: "col-3"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.page.sectionTitle), 1)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass([
          'card-body',
          'd-flex',
          'justify-content-center',
          'card-body-image',
          _ctx.page.customIconName ? 'align-items-center' : ''
        ])
        }, [
          (_ctx.page.svgIcon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                style: {"width":"50%"},
                src: _ctx.page.svgIcon
              }, null, 8, _hoisted_4))
            : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                class: "custom-icon-svg",
                icon: _ctx.iconFinder(_ctx.page.customIconName)
              }, null, 8, ["icon"]))
        ], 2),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.page.description), 1)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}