
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { DonViService } from "@/core/services/DonVi.service";
import { swalErrNotification } from '../core/helpers/utils';

import DashboardCards from '../components/shared/dashboard-cards/DashboardCards.vue';
import PageRowLayout from '../layout/_shared/PageRowLayout.vue';
import DivCol from '../layout/_shared/DivCol.vue';
import DivRow from '../layout/_shared/DivRow.vue';
import LabelCustom from '../components/shared/label-custom/LabelCustom.vue';

export default defineComponent({
  name: "dashboard",

  components: { DashboardCards, DivCol, DivRow, LabelCustom, PageRowLayout },

  setup() {
    const store = useStore();
    const departmentDetail = ref({
      id: 0,
      name: "",
      address: "",
    });

    onMounted(async () => {
      setCurrentPageTitle("Trang chủ");
      try {
        const res = await DonViService.fetchCurrentDepartmentById(
          store.getters.currentUser.departmentId
        );
        const fetchedDepartment = res.data.data;
        if (fetchedDepartment) {
          departmentDetail.value.id = fetchedDepartment.id;
          departmentDetail.value.name = fetchedDepartment.ten_don_vi;
          departmentDetail.value.address = fetchedDepartment.dia_chi;
        }
      } catch (err) {
        swalErrNotification(
          err,
          'Có lỗi khi lấy thông tin đơn vị hiện tại',
        )
      }
    });

    return {
      departmentDetail,
      currentYear: computed(() => store.getters.getSelectedYear),
    };
  },
});
