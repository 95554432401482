import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_label_custom = _resolveComponent("label-custom")!
  const _component_DivCol = _resolveComponent("DivCol")!
  const _component_DivRow = _resolveComponent("DivRow")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!
  const _component_dashboard_cards = _resolveComponent("dashboard-cards")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageRowLayout, null, {
      "page-row-header": _withCtx(() => [
        _createTextVNode(" Thông tin đơn vị ")
      ]),
      "page-row-content": _withCtx(() => [
        _createVNode(_component_DivRow, null, {
          default: _withCtx(() => [
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_label_custom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Tên đơn vị:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 5 }, {
              default: _withCtx(() => [
                _createVNode(_component_label_custom, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.departmentDetail.name), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_DivRow, null, {
          default: _withCtx(() => [
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_label_custom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Địa chỉ:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_label_custom, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.departmentDetail.address), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_DivRow, null, {
          default: _withCtx(() => [
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_label_custom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Năm làm việc:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 5 }, {
              default: _withCtx(() => [
                _createVNode(_component_label_custom, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentYear), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_PageRowLayout, null, {
      "page-row-header": _withCtx(() => [
        _createTextVNode(" Quản lý ")
      ]),
      "page-row-content": _withCtx(() => [
        _createVNode(_component_dashboard_cards)
      ]),
      _: 1
    })
  ], 64))
}