
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ButtonsType } from '../../../../core/enums/buttons.enums';
// import DivCol from '../../../../layout/_shared/DivCol.vue';

export default defineComponent({
  name: 'dashboard-card',

  components: {
    // DivCol,
    FontAwesomeIcon,
  },

  props: {
    page: {
      type: Object,
      required: true,
    }
  },

  methods: {
    iconFinder(customIcon) {
      switch (customIcon) {
        case ButtonsType.FA_FILE_CIRCLE_XMARK:
          return 'fa-solid fa-file-circle-xmark'
        default:
          return 'fa-solid fa-xmark';
      }
    }
  }
})
